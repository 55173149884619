import { MANAGE_MNP_PORTIN, GET_PORTIN_LIST, GET_PORTIN_STATUS, PORTIN_REQUEST_NOTIFICATION } from "../constants";

const initialState = { 
  portInListData: {},
  portInStatusData: {},
  portInRequestData: {},
  portinRequestNotification:{
    message: '',
    data:'',
    isSuccess: 1
  },
}
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_PORTIN_LIST: {
      return {
        ...state,
        portInListData: payload,
      }
    }
    case GET_PORTIN_STATUS: {
      return {
        ...state,
        portInStatusData: payload,
      }
    }
    case MANAGE_MNP_PORTIN: {
      return {
        ...state,
        portInRequestData: payload,
      };
    }
    case PORTIN_REQUEST_NOTIFICATION:
      return { ...state, 
        portinRequestNotification: (payload.data) ? payload : initialState.portinRequestNotification
      }

    default:
      return state;
    }
}

export default reducer;