import {
    GET_CITY, INSERT_CUSTOMER, MANAGE_SIM_DETAILS, UPDATE_CUSTOMER,
  } from '../constants';

const initialState = { 
  countries:[],
  crmCustomers:[],
  simDetails: [],
}
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_CITY: {
      return {
        ...state,
        cities: payload,
      }
    }
    case INSERT_CUSTOMER: {
      return { ...state, crmCustomers:[payload, ...state.crmCustomers] }
    }
  
    case UPDATE_CUSTOMER: {
      return {
        ...state, 
        crmCustomers:
         state.crmCustomers.map(item => item.simcustomerid === payload.simcustomerid ? 
          { ...item, ...payload} : item 
        )
      }
    }

    case MANAGE_SIM_DETAILS: {
      return { ...state, simDetails:[payload, ...state.simDetails] }
    }

    default:
      return state;
  }
}

export default reducer;