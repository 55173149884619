import {
  GET_TICKET_TYPES,
  MANAGE_SUPPORT_QUESTION,
  GET_ANSWER_TICKET,
  GET_USER_TICKET,
  MANAGE_SUPPORT_RESPONSE,
  TICKET_NOTIFICATION
  } from 'redux/constants';
  
  const initialState = {
    allTicketTypes: [],
    supportQuestionDetails: {},
    answeredTicketsData: [],
    userTicketsData: [],
    supportResponse: {},
    ticket_notification:{
      message: '',
      isSuccess: 1
    },
  };
  const reducer = (state = initialState, action) => {
  
    const { type, payload } = action;
  
    switch (type) {
  
      case GET_TICKET_TYPES: {
        return {
          ...state,
          allTicketTypes: payload,
        };
      }

      case MANAGE_SUPPORT_QUESTION: {
        return { ...state, supportQuestionDetails:payload }
      }   
      
      case MANAGE_SUPPORT_RESPONSE:
        return {
          ...state,
          supportResponse: payload,
        };

      case GET_ANSWER_TICKET: {
        return {
          ...state,
          answeredTicketsData: payload,
        };
      }

      case GET_USER_TICKET: {
        return {
          ...state,
          userTicketsData: payload,
        };
      }

      case TICKET_NOTIFICATION:
      return { ...state, 
        ticket_notification: (payload.message) ? payload : initialState.ticket_notification
      }

      default:
        return state;
    }
  };
  
  export default reducer;
  