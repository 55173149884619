import { GET_USER_DETAILS, CHANGE_PASSWORD } from "../constants";

const initialState = { 
  userDetails:[],
}
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_USER_DETAILS: {
      return {
        ...state,
        userDetails: payload,
      }
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: payload,
      };
    }
    default:
      return state;
    }
}

export default reducer;