import { 
  CHANGE_OPTION,
  COUNTER_QUERY,
  GET_CALL_RECORD,
  GET_PADS_RECORD, 
  GET_SUBSCRIPTION_RECORD, 
  GET_ACCOUNT_COUNT_RECORD, 
  GET_ADJUSTMENT_TYPE_TICKET, 
  GET_SMS_RECORD,
  GET_CDR_TYPE,
  INSERT_CDR_REPORT,  
  CHANGE_STATUS,
  GET_CRM_VOUCHER_TOPUP_REPORT,
  CUSTOMER_COUNTER_QUERY,
} from "redux/constants";

const initialState = {
  counterQueryData: {},
  customerCounterQueryData: {},
  changeOptionDetails : {},
  callRecordData : [],
  padsRecordData : [],
  subscriptionHistoryRecordData : [],
  accountCountRecordData : [],
  adjustmentTypeRecordData : [],
  smsRecordData : [],
  cdrTypes : [],
  cdrReport : [],
  changeStatusData: {},
  voucherTopupReport : [],
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COUNTER_QUERY: {
      return {
        ...state,
        counterQueryData: payload,
      };
    }

    case CUSTOMER_COUNTER_QUERY: {
      return {
        ...state,
        customerCounterQueryData: payload,
      };
    }

    case CHANGE_OPTION:
      return {
        ...state,
        changeOptionDetails: payload,
      };

      case GET_CALL_RECORD: {
        return {
          ...state,
          callRecordData: payload,
        };
      }

      case GET_PADS_RECORD: {
        return {
          ...state,
          padsRecordData: payload,
        };
      }

      case GET_SUBSCRIPTION_RECORD: {
        return {
          ...state,
          subscriptionHistoryRecordData: payload,
        };
      }

      case GET_ACCOUNT_COUNT_RECORD: {
        return {
          ...state,
          accountCountRecordData: payload,
        };
      }

      case GET_ADJUSTMENT_TYPE_TICKET: {
        return {
          ...state,
          adjustmentTypeRecordData: payload,
        };
      }

      case GET_SMS_RECORD: {
        return {
          ...state,
          smsRecordData: payload,
        };
      }

      case GET_CDR_TYPE: {
        return {
          ...state,
          cdrTypes: payload,
        };
      }

      case INSERT_CDR_REPORT: {
        return { ...state, cdrReport:[payload , ...state.cdrReport] }
      }

      case CHANGE_STATUS: {
        return {
          ...state,
          changeStatusData: payload,
        };
      }

      case GET_CRM_VOUCHER_TOPUP_REPORT: {
        return {
          ...state,
          voucherTopupReport: payload,
        };
      }

    default:
      return state;
  }
};
export default reducer;
