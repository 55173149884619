export const getCartSession = () => {
    const data = localStorage.getItem('tariffData');
    if (data && data !== 'undefined') {
       return JSON.parse(data);
    }
  }
  
  export const removeCartSession = () => {
    localStorage.removeItem('tariffData');
  }
  
  export const setCartSession = (data) => {
    localStorage.setItem('tariffData', JSON.stringify(data));
  }