const {
  LOGIN_USER_SUCCESS,
  LOGIN_NOTIFICATION,
  REGISTER_USER_SUCCESS,
  FORGOT_PASSWORD,
  VERIFY_USER,
  RESET_PASSWORD,
  LOGOUT_USER,
  VERIFY_USER_SUCCESS,
  GUEST_USER_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_RESET_LINK,
  VERIFY_NUMBER_SUCCESS,
} = require("../constants");

const initialState = {
  loginNotification: {
    message: "",
    isSuccess: 1,
  },
  login_data: {},
  register_data: {},
  forgot_data: {},
  verify_data: {},
  reset_data: {},
  verification_data: {},
  guest_user_data: {},
  email_verification_data: {},
  reset_verification_data: {},
  mobNumError: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_NOTIFICATION:
      return {
        ...state,
        loginNotification: payload.message
          ? payload
          : initialState.loginNotification,
      };

    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        login_data: payload,
      };
    }

    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        register_data: payload,
      };
    }

    case GUEST_USER_SUCCESS: {
      return {
        ...state,
        guest_user_data: payload,
      };
    }

    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        verification_data: payload,
      };
    }

    case FORGOT_PASSWORD: {
      return {
        ...state,
        forgot_data: payload,
      };
    }

    case VERIFY_USER: {
      return {
        ...state,
        verify_data: payload,
      };
    }

    case VERIFY_EMAIL: {
      return {
        ...state,
        email_verification_data: payload,
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        reset_data: payload,
      };
    }

    case VERIFY_RESET_LINK: {
      return {
        ...state,
        reset_verification_data: payload,
      };
    }
    case VERIFY_NUMBER_SUCCESS: {
      return {
        ...state,
        mobNumError: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
